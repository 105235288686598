import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Survey from "../components/survey"
import HeroSurvey from "../components/heroSurvey"

const SurveyPage = () => (
  <Layout>
    <SEO title="Questionario" />
    <Header />
    <HeroSurvey />
    <Survey />
  </Layout>
)

export default SurveyPage
