import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import {
  BtnGroup,
  BtnLink,
  BtnLinkSecondary,
  BtnLinkTertiary,
} from "../components/button.js"
import data from "../data/survey"
import Profile from "./profile"
import DesignTokens from "./designTokens"
import { Display, DisplaySmall, Body } from "./typography"

const questions = data.questions

const Survey = () => {
  const [survey, setSurvey] = useState(questions)
  const [score, setScore] = useState(0)
  const [count, setCount] = useState(0)

  const handleClick = (clickedAnswer, clickedQuestion) => {
    console.log(survey)
    console.log(clickedAnswer.uuid)

    if (clickedAnswer.isCorrect === true) {
      setScore(score + 1)
    }

    setCount(count + 1)

    const disabledValue = survey.map(question => ({
      ...question,
      answers: question.answers.map(answer =>
        question.uuid === clickedQuestion.uuid
          ? { ...answer, isDisabled: true }
          : answer
      ),
    }))

    const newValue = disabledValue.map(question => ({
      ...question,
      isAnswered:
        question.uuid === clickedQuestion.uuid ? true : question.isAnswered,
      isAnsweredCorrectly:
        question.uuid === clickedQuestion.uuid
          ? clickedAnswer.isCorrect
          : question.isAnsweredCorrectly,
      answers: question.answers.map(answer =>
        answer.uuid === clickedAnswer.uuid
          ? { ...answer, isSelected: true }
          : answer
      ),
    }))
    setSurvey(newValue)
  }
  
  return (
    <div>
      {survey.map(question => (
        <section key={question.uuid}>
          {count >= question.order ? (
            <Question>
              <Container>
                <Row justify="center">
                  <Col xs={12} sm={12} md={9}>
                    <Wrapper>
                      {question.order === 9 ? (
                        <Index>
                          10<span>/10</span>
                        </Index>
                      ) : (
                        <Index>
                          {`0` + (question.order + 1)}
                          <span>/10</span>
                        </Index>
                      )}
                      <DisplaySmall weight={700} align="center">
                        {question.text}
                      </DisplaySmall>
                      <BtnGroup>
                        {question.answers.map(answer => (
                          <BtnLinkTertiary
                            disabled={answer.isDisabled}
                            correct={answer.isCorrect && question.isAnswered}
                            as="button"
                            key={answer.uuid}
                            onClick={() => handleClick(answer, question)}
                          >
                            {answer.text}
                          </BtnLinkTertiary>
                        ))}
                      </BtnGroup>
                      {question.isAnswered && (
                        <Body weight={700} align="center">
                          {question.description}
                        </Body>
                      )}
                    </Wrapper>
                  </Col>
                </Row>
              </Container>
            </Question>
          ) : null}
        </section>
      ))}
      {count === 10 ? <Profile score={score} /> : null}
    </div>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Index = styled.div`
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  span {
    opacity: 0.5;
  }
`

const Question = styled.div`
  padding: 5rem 0;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.primary[500]};
  border-bottom: 1px solid ${DesignTokens.colors.primary[300]};
  @media screen and (max-width: 1023px) {
    padding: 3rem 0;
  }
`

export default Survey
