import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import heroImage from "../images/illu-survey.jpg"
import { Container, Row, Col, Visible } from "react-grid-system"
import { BtnLink } from "./button"
import { Display, DisplaySmall, Body } from "./typography"

const HeroSurvey = () => (
  <Wrapper>
    <CustomContainer background={heroImage}>
      <Row>
        <Content xs={12} md={12} lg={10} xl={7}>
          <Display>
            Quanto ne sai sulla Social Corporate Responsibility?
          </Display>
          <DisplaySmall>
            10 domande per scoprirlo
          </DisplaySmall>
        </Content>
      </Row>
    </CustomContainer>
  </Wrapper>
)

const Content = styled(Col)`
  padding: 6rem 0;
  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }

  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    @media screen and (max-width: 1023px) {
      background-position: center;
      opacity: 0.2;
    }
  }
`

const Image = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Wrapper = styled.section`
  hr {
    width: 64px;
    height: 3px;
    background: ${DesignTokens.colors.black};
  }
`

export default HeroSurvey
