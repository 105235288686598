import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import { Display, DisplaySmall, Body } from "./typography"
import SimpleForm from "./simpleForm"

const Profile = ({ score }) => {
  let profile = {}
  if (score <= 3) {
    profile = {
      title: "CSR, questa oscura sigla",
      text:
        "Fino ad oggi CSR voleva dire “Ci Si Rivede”. Questo non significa però che la Responsabilità Sociale di Impresa sia a te estranea. Ci sono alcune attività che molte aziende già fanno e che possono essere considerate socialmente responsabili, e spesso il primo passo nella CSR sta proprio nel riconoscerle.",
      hint:
        "MULTI può aiutarti a comprendere il valore aggiunto della CSR rispetto alle più comuni attività di corretta gestione aziendale, e quali vantaggi porta avviare un percorso di Responsabilità Sociale di Impresa.",
    }
  }
  if (score > 3 && score <= 6) {
    profile = {
      title: "La CSR dietro l'angolo",
      text:
        "CSR o meno, quando si parla di responsabilità aziendale sei sul pezzo! Una produzione sostenibile, ad esempio, è importante sia per l’azienda che per l’ambiente. Sapevi che lo stesso approccio può essere usato anche nei confronti di dipendenti, fornitori e consumatori finali?",
      hint:
        "MULTI può aiutarti a capire come implementare attività di responsabilità aziendale con attività di responsabilità sociale per abbracciare un percorso di CSR a 360°.",
    }
  }
  if (score > 6 && score <= 8) {
    profile = {
      title: "CSR, mai più senza",
      text:
        "Hai fatto i compiti a casa, si vede! Le tue conoscenze dimostrano quanto tu ti senta socialmente coinvolto, sia come attitudine green che visione al futuro. Attenzione però a non pensare la CSR come una tendenza di questi anni. La responsabilità chiama responsabilità, va alimentata costantemente.",
      hint:
        "MULTI può aiutarti a capire come trasformare le tue idee in azioni concrete, studiando il percorso di Responsabilità Sociale più adatto alla tua impresa.",
    }
  }
  if (score > 8) {
    profile = {
      title: "Pane, amore e CSR",
      text:
        "Complimenti! Mastichi bene la materia, ma la CSR è un viaggio che non conosce fine. Ci sono innumerevoli attività da sviluppare attorno all’azienda per raggiungere nuovi obiettivi e ottenere vantaggi inaspettati in termini di awareness e reputation.",
      hint:
        "MULTI può aiutarti a costruire oppure ottimizzare il tuo impegno sociale e delineare la tua vision futura in un percorso di Responsabilità Sociale di Impresa che valorizzi i tuoi obiettivi di crescita.",
    }
  }
  return (
    <Wrapper>
      <CustomContainer>
        <Row justify="center">
          <Col xs={12} sm={12} md={9}>
            <Content>
              {profile && (
                <>
                  <Index>
                    {score} risposte corrette <span>su 10</span>
                  </Index>
                  <DisplaySmall weight={700} align="center">
                    {profile.title}
                  </DisplaySmall>
                  <Body weight={700} align="center">
                    {profile.text}
                  </Body>
                  <Body
                    weight={700}
                    align="center"
                    color={DesignTokens.colors.black}
                  >
                    {profile.hint}
                  </Body>
                </>
              )}
            </Content>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={12} sm={12} md={9} lg={6}>
            <SimpleForm />
          </Col>
        </Row>
      </CustomContainer>
    </Wrapper>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomContainer = styled(Container)`
    & > * {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
`

const Index = styled.div`
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  span {
    opacity: 0.5;
  }
`

const Wrapper = styled.div`
  padding: 5rem 0;
  color: ${DesignTokens.colors.primary[500]};
  background: ${DesignTokens.colors.tertiary[500]};
  border-bottom: 1px solid ${DesignTokens.colors.primary[300]};
`

export default Profile
